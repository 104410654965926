<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
import { UserIcon, CheckSquareIcon, SquareIcon } from "vue-feather-icons";
import VForm from "../forms/VForm.vue";
import GlobalVue from "@helper/Global.vue";

export default {
  extends: BaseVue, GlobalVue,
  data() {
    return {
      data: {},
      static_content: {},
      error: false,
    };
  },
  components: {
    UserIcon,
    CheckSquareIcon,
    SquareIcon,
  },
  computed: {},
  async mounted() {
    if (!this.$route.query.code) {
      this.error = "Oopss Data Tidak Ditemukan";
    }
    this.getContent();
    await this.ready();
  },
  methods: {
    download() {
      window.location = this.baseUrl + "/result-ppdb/" + this.$route.query.code;
    },
    refreshMeta() {
      Gen.rest('/thank-you-page', { metainfo: true }, (err, resp) => {
        if (err) return console.error(err);

        $("[rel=alternate]").remove()
        $("[rel=canonical]").remove()
        
        if(this.$route.name == "Index"){
          $("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        } 
        else if(this.$route.name && this.$route.name !== "404"){
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        }
        else{
          $("[name='robots']").attr('content','noindex,nofollow')
          $("[name='googlebot']").attr('content','noindex,nofollow')
        }
        
        $("title").text(resp.meta_title);
        $("meta[name='keywords']").attr('content', resp.meta_keyword)
        $("meta[name='description']").attr('content', resp.meta_desc)
        $("meta[name='author']").attr("content", 'SDI Al Hidayah');

        // OG
        $("meta[property='og:image']").attr('content', resp.ss_img)
        $("meta[property='og:title']").attr('content', resp.meta_title)
        $("meta[property='og:keywords']").attr('content', resp.meta_keyword)
        $("meta[property='og:description']").attr('content', resp.meta_desc)
        $("meta[property='og:url']").attr('content', resp.ss_url)
        
        // Twitter
        $("meta[name='twitter:image']").attr('content', resp.ss_img)
        $("meta[name='twitter:title']").attr('content', resp.meta_title)
        $("meta[name='twitter:description']").attr('content', resp.meta_desc)
        $("meta[name='twitter:url']").attr('content', resp.ss_url)
        this.$root.meta = resp;
      });
    },
    getContent(e) {
      Gen.apirest(
        "/detail-registration/detail/" + this.$route.query.code,
        {},
        (err, resp) => {
          if (err) {
            this.error = err.resp.message;
            return this.$router.push({name : '404'});
          }
          this.refreshMeta();
          this.data = resp.data;
          this.static_content = resp.static_content;
        }
      );
    },
  },
};
</script>
<template>
  <section id="content" class="mt-0">
    <div class="content-wrap">
      <section v-if="!this.error" class="section py-sm">
        <div class="container">
          <div class="form-head text-center">
            <img
              :src="assets('fo_images', 'logo.png')"
              alt="Logo SDI Al Hidayah"
              title="Logo SDI Al Hidayah"              
              class="form-head__img"
            />
          </div>
          <div class="form-body">
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-10">
                <div class="form-body-content">
                  <h2 itemprop="alternativeHeadline" class="form-welcome__title">Terima Kasih!</h2>
                  <div itemprop="description" class="form-welcome-content">
                    <div class="alert alert-success">
                      <span
                        >Kami telah mengirimkan email ke
                        <strong>{{ data.ap_memail }}</strong>. Silakan cek email
                        Anda untuk melihat informasi selengkapnya.</span
                      >
                    </div>
                    <div v-html="static_content.as_val"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-10">
                <div class="row justify-content-center">
                  <div class="col-md-12 text-center">
                    <p itemprop="description">
                      Di bawah ini terlampir data yang telah Anda daftarkan. Klik tombol
                      <strong>"Unduh Data Pendaftaran"</strong> untuk mengunduh data berikut.
                    </p>
                  </div>
                  <div class="col-md-auto">
                    <p class="text-center text-md-right">
                      <button @click="download" class="cta cta--primary">
                        Unduh Data Pendaftaran
                      </button>
                    </p>
                  </div>
                </div>
                <div v-if="!isMobile" itemscope itemtype="https://schema.org/Person" class="form-registration-data" id="registrationData">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle"
                              >DATA SISWA/I</strong
                            >
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="2">: {{ data.ap_sname }}</td>
                          <td rowspan="11" class="data-img" width="240px">
                            <img itemprop="image" :src="uploader(data.ap_sphoto)" :alt="data.ap_sname" :title="data.ap_sname" />
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Jenis Kelamin</td>
                          <td itemprop="gender" class="data" colspan="2">: {{ data.ap_sgender }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="2">
                            : <span itemprop="birthPlace">{{ data.ap_splace_of_birth }}</span>,
                            <span itemprop="birthDate">{{ data.ap_sdate_of_birth && this.dateIdFormat(new Date(data.ap_sdate_of_birth)) }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat</td>
                          <td itemprop="address" class="data" colspan="2">: {{ data.ap_saddress }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Mendaftar ke Kelas</td>
                          <td itemprop="name" class="data" colspan="2">: {{ data.ap_sclass_register }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">TK Asal</td>
                          <td itemprop="name" class="data" colspan="2">: {{ data.ap_stk || "-" }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Agama</td>
                          <td itemprop="name" class="data" colspan="2">: {{ data.ap_sreligion }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Status dalam Keluarga</td>
                          <td itemprop="name" class="data" colspan="2">: Anak Kandung</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Anak ke-</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_snth_child }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Dokumen</td>
                          <td itemprop="name" class="data" colspan="2">
                            : Akta Kelahiran
                            <check-square-icon
                              v-if="data.ap_sakta"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Kartu Keluarga
                            <check-square-icon
                              v-if="data.ap_skk"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Pas Foto
                            <check-square-icon
                              v-if="data.ap_sphoto"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Ijazah TK
                            <check-square-icon
                              v-if="data.ap_stk_ijazah"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Tes Psikotes
                            <check-square-icon
                              v-if="data.ap_stk_psikotest"
                            ></check-square-icon
                            ><square-icon v-else></square-icon>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA IBU</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_mname }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="3">
                            : <span itemprop="birthPlace">{{ data.ap_mplace_of_birth }}</span>,
                            <span itemprop="birthDate">{{ data.ap_mdate_of_birth && this.dateIdFormat(new Date(data.ap_mdate_of_birth)) }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_maddress }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="3">
                            : {{ data.ap_mphone }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="3">: {{ data.ap_mjob }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_mjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Agama</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_mreligion }}
                          </td>
                        </tr>
                        <tr>
                          <td width="250px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="3">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_mlast_education }}
                              </span>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA AYAH</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_fname || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="3">
                            :
                            <span itemprop="birthPlace">{{
                              data.ap_fplace_of_birth
                                ? data.ap_fplace_of_birth + ", "
                                : "-"
                              }}
                            </span>
                            <span itemprop="birthDate">
                              {{ data.ap_fdate_of_birth && this.dateIdFormat(new Date(data.ap_fdate_of_birth)) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_faddress || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="3">
                            : {{ data.ap_fphone || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="3">
                            : {{ data.ap_fjob || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_fjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Agama</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_freligion || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="3">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_flast_education || "-" }}
                              </span>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA WALI</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_pname || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="3">
                            :
                            <span itemprop="birthPlace">
                              {{
                                data.ap_pplace_of_birth
                                  ? data.ap_pplace_of_birth + ", "
                                  : "-"
                              }}
                            </span>
                            <span itemprop="birthDate">
                              {{ data.ap_pdate_of_birth && this.dateIdFormat(new Date(data.ap_pdate_of_birth)) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_paddress || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="3">
                            : {{ data.ap_pphone || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="3">
                            : {{ data.ap_pjob || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_pjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Agama</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_preligion || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="3">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_plast_education || "-" }}
                              </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="250px"><b>Email Ayah/Ibu</b></td>
                          <td itemprop="email" class="data" colspan="3">
                            : {{ data.ap_memail || data.ap_memail }}
                          </td>
                        </tr>
                        <tr class="main table-active">
                          <td colspan="10">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DOKUMEN</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Akta Kelahiran</td>
                          <td>Kartu Keluarga</td>
                          <td>Ijazah TK</td>
                          <td>Hasil Psikotes</td>
                        </tr>
                        <tr>
                          <td>
                            <img itemprop="image" :src="uploader(data.ap_sakta)" alt="Akta Kelahiran" title="Akta Kelahiran" />
                          </td>
                          <td>
                            <img itemprop="image" :src="uploader(data.ap_skk)" alt="Kartu Keluarga" title="Kartu Keluarga" />
                          </td>
                          <td>
                            <img v-if="data.ap_stk_ijazah" itemprop="image" :src="uploader(data.ap_stk_ijazah)" alt="Ijazah TK" title="Ijazah TK" />
                          </td>
                          <td>
                            <img v-if="data.ap_stk_psikotest" itemprop="image" :src="uploader(data.ap_stk_psikotest)" alt="Hasil Psikotes" title="Hasil Psikotes" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center mt-3">
                      <router-link
                        :to="{name: 'Index'}"
                        class="cta cta--primary tab-linker"
                        >Kembali ke Beranda</router-link
                      >
                    </div>
                  </div>
                </div>
                <div v-else itemscope itemtype="https://schema.org/Person" class="form-registration-data" id="registrationData">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle"
                              >DATA SISWA/I</strong
                            >
                          </td>
                        </tr>
                        <tr>
                          <td colspan="12" class="data-img m__data">
                            <img itemprop="image" class="img-murid" :src="uploader(data.ap_sphoto)" :alt="data.ap_sname" :title="data.ap_sname" />
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Nama Lengkap</td>
                          <td itemprop="name" class="data">: {{ data.ap_sname }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Jenis Kelamin</td>
                          <td itemprop="gender" class="data">: {{ data.ap_sgender }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Tempat, Tanggal Lahir</td>
                          <td  class="data">
                            : <span itemprop="birthPlace">{{ data.ap_splace_of_birth }}</span>,
                            <span itemprop="birthDate">{{ data.ap_sdate_of_birth && this.dateIdFormat(new Date(data.ap_sdate_of_birth)) }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat</td>
                          <td itemprop="address" class="data">: {{ data.ap_saddress }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Mendaftar ke Kelas</td>
                          <td itemprop="name" class="data">: {{ data.ap_sclass_register }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">TK Asal</td>
                          <td itemprop="name" class="data">: {{ data.ap_stk || "-" }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Agama</td>
                          <td itemprop="name" class="data">: {{ data.ap_sreligion }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Status dalam Keluarga</td>
                          <td itemprop="name" class="data" colspan="2">: Anak Kandung</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Anak ke-</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_snth_child }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Dokumen</td>
                          <td itemprop="name" class="data" colspan="2">
                            : Akta Kelahiran
                            <check-square-icon
                              v-if="data.ap_sakta"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Kartu Keluarga
                            <check-square-icon
                              v-if="data.ap_skk"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Pas Foto
                            <check-square-icon
                              v-if="data.ap_sphoto"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Ijazah TK
                            <check-square-icon
                              v-if="data.ap_stk_ijazah"
                            ></check-square-icon
                            ><square-icon v-else></square-icon> | Tes Psikotes
                            <check-square-icon
                              v-if="data.ap_stk_psikotest"
                            ></check-square-icon
                            ><square-icon v-else></square-icon>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA IBU</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_mname }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="2">
                            : <span itemprop="birthPlace">{{ data.ap_mplace_of_birth }}</span>,
                            <span itemprop="birthDate">{{ data.ap_mdate_of_birth && this.dateIdFormat(new Date(data.ap_mdate_of_birth)) }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="2">
                            : {{ data.ap_maddress }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="2">
                            : {{ data.ap_mphone }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="2">: {{ data.ap_mjob }}</td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="2">
                            : {{ data.ap_mjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Agama</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_mreligion }}
                          </td>
                        </tr>
                        <tr>
                          <td width="150px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="2">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_mlast_education }}
                              </span>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA AYAH</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_fname || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="2">
                            :
                            <span itemprop="birthPlace">{{
                              data.ap_fplace_of_birth
                                ? data.ap_fplace_of_birth + ", "
                                : "-"
                              }}
                            </span>
                            <span itemprop="birthDate">
                              {{ data.ap_fdate_of_birth && this.dateIdFormat(new Date(data.ap_fdate_of_birth)) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="2">
                            : {{ data.ap_faddress || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="2">
                            : {{ data.ap_fphone || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="2">
                            : {{ data.ap_fjob || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="2">
                            : {{ data.ap_fjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Agama</td>
                          <td itemprop="name" class="data" colspan="2">
                            : {{ data.ap_freligion || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="2">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_flast_education || "-" }}
                              </span>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="10" class="main table-active">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DATA WALI</strong>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Nama Lengkap</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_pname || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Tempat, Tanggal Lahir</td>
                          <td class="data" colspan="3">
                            :
                            <span itemprop="birthPlace">
                              {{
                                data.ap_pplace_of_birth
                                  ? data.ap_pplace_of_birth + ", "
                                  : "-"
                              }}
                            </span>
                            <span itemprop="birthDate">
                              {{ data.ap_pdate_of_birth && this.dateIdFormat(new Date(data.ap_pdate_of_birth)) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Tempat Tinggal</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_paddress || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">No. HP / Telepon</td>
                          <td itemprop="telephone" class="data" colspan="3">
                            : {{ data.ap_pphone || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Pekerjaan</td>
                          <td itemprop="jobTitle" class="data" colspan="3">
                            : {{ data.ap_pjob || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Alamat Kantor</td>
                          <td itemprop="address" class="data" colspan="3">
                            : {{ data.ap_pjob_address || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Agama</td>
                          <td itemprop="name" class="data" colspan="3">
                            : {{ data.ap_preligion || "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Pendidikan Terakhir</td>
                          <td itemscope itemtype="https://schema.org/EducationalOccupationalCredential" class="data" colspan="3">
                            : <span itemprop="educationalCredentialAwarded">
                                {{ data.ap_plast_education || "-" }}
                              </span>
                          </td>
                        </tr>
                        <tr>
                          <td itemprop="name" width="150px">Email Ayah/Ibu</td>
                          <td itemprop="email" class="data" colspan="2">
                            : {{ data.ap_memail || data.ap_memail }}
                          </td>
                        </tr>
                        <tr class="main table-active">
                          <td colspan="10">
                            <user-icon></user-icon>
                            <strong itemprop="name" class="ml-2 align-middle">DOKUMEN</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Akta Kelahiran</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="m__data">
                            <img itemprop="image" :src="uploader(data.ap_sakta)" alt="Akta Kelahiran" title="Akta Kelahiran" />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Kartu Keluarga</td>
                        </tr>
                        <tr>
                           <td colspan="2" class="m__data">
                            <img itemprop="image" :src="uploader(data.ap_skk)" alt="Kartu Keluarga" title="Kartu Keluarga" />
                          </td>
                        </tr>
                        <tr v-if="data.ap_stk_ijazah">
                          <td colspan="2">Ijazah TK</td>
                        </tr>
                        <tr>
                         <td v-if="data.ap_stk_ijazah" colspan="2" class="m__data">
                            <img itemprop="image" :src="uploader(data.ap_stk_ijazah)" alt="Ijazah TK" title="Ijazah TK" />
                          </td>
                        </tr>
                        <tr>
                          <td v-if="data.ap_stk_psikotest" colspan="2">Hasil Psikotes</td>
                        </tr>
                        <tr>
                          <td v-if="data.ap_stk_psikotest" colspan="2" class="m__data">
                            <img itemprop="image" :src="uploader(data.ap_stk_psikotest)" :alt="data.ap_sname" :title="data.ap_sname" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center mt-3">
                      <router-link
                        :to="{name: 'Index'}"
                        class="cta cta--primary tab-linker"
                        >Kembali ke Beranda</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content" v-else>
        <div class="content-wrap">
          <section class="section pt-sm">
            <div class="container">
              <div class="notfound">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <img
                      :src="assets('fo_images', '404.svg')"
                      alt="Halaman Tidak Ditemukan"
                      title="Halaman Tidak Ditemukan"
                      class="notfound__img"
                    />
                  </div>
                  <div class="w-100"></div>
                  <div class="col-lg-6 col-md-8">
                    <div class="notfound-meta text-center">
                      <h2 class="notfound-meta__title">
                        Kami Tidak Dapat Menemukan Halaman yang Anda Cari
                      </h2>
                      <router-link
                        :to="{ name: 'Index' }"
                        class="cta cta--primary"
                        >Kembali ke Beranda</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </section>
</template>

<style scoped>
  .m__data {
    text-align: center;
  }

  .m__data img {
    display: inline;
  }
  
  td img {
    display: block;
    max-width:170px;
    max-height:226.67px;
    width: auto;
    height: auto;;
  }
</style>
